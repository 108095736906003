import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  messageContainer: {
    height:  ({ height, qrHeight, WIDE_MODE }) => `${ WIDE_MODE ? height - 96 - qrHeight : (height * 0.75) - 96 - qrHeight}px`,
    // '&.showQr': {
    //   height: ({ height, WIDE_MODE })  => `${ WIDE_MODE ? height - 148 : height * 0.75 - 150}px`,
    // },
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    borderRadius: ({ WIDE_MODE }) => WIDE_MODE ? '28px 28px 0 0' : 0,
    padding:({ WIDE_MODE }) => WIDE_MODE ? '20px 25px 15px 25px' : '0px 25px 15px 25px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 20px 15px 25px',
      height: ({ height, qrHeight, SHOW_WATERMARK }) => `${ height - (SHOW_WATERMARK ? 150 : 120) - (qrHeight === 0 ? 0 : qrHeight + 7)}px`,
      // '&.showQr': {
      //   height: ({ height }) => `${ height - 170}px`,
      // }
    },
  },
  postBackMsgAnimation: {
    '&-enter': {
      transform: 'translateY(100px) translateX(-50px) scale(0.4)',
      transformOrigin: 'bottom'
    },
    '&-enter-active': {
      transform: 'translateY(0px) translateX(0px) scale(1)',
      transition: 'transform 0.4s ease-in-out'
    },
    '&-exit': {
      opacity: 1
    },
    '&-exit-active': {
      opacity: 0,
      transition: 'opacity 500ms ease-in'
    }
  },
  notificationAnimation: {
    '&-enter': {
      transform: 'scale(1) translate(500px, 0px)',
      transformOrigin: 'right',
    },
    '&-enter-active': {
      transition: 'transform 0.3s ease-in-out',
      transform: 'scale(1)'
    },
    '&-exit': {
      opacity: 1
    },
    '&-exit-active': {
      opacity: 0,
      transition: 'opacity 500ms ease-in'
    }
  },
  userMsgAnimation: {
    '&-enter': {
      transform: 'scale(0)',
      transformOrigin: 'bottom right',
    },
    '&-enter-active': {
      transition: 'transform 0.3s ease-in-out',
      transform: 'scale(1)'
    },
    '&-exit': {
      opacity: 1
    },
    '&-exit-active': {
      opacity: 0,
      transition: 'opacity 500ms ease-in'
    }
  },
  botMsgAnimation: {
    '&-enter': {
      transform: 'scale(0)',
      transformOrigin: 'bottom left',
    },
    '&-enter-active': {
      transition: 'transform 0.3s ease-in-out',
      transform: 'scale(1)'
    },
    '&-exit': {
      opacity: 1
    },
    '&-exit-active': {
      opacity: 0,
      transition: 'opacity 500ms ease-in'
    }
  },
  typingDot: {
    height: 5,
    width: 5,
    borderRadius: '100%',
    marginLeft: 4,
    display: 'inline-block',
    backgroundColor: '#5C5E69',
    animation: '1.2s $typing-dot ease-in-out infinite',
    '&:nth-of-type(2)': {
      animationDelay: '0.15s'
    },
    '&:nth-of-type(3)': {
      animationDelay: '0.25s'
    }
  },
  '@keyframes typing-dot': {
    '15%': {
      transform: 'translateY(-35%)',
      opacity: 0.5
    },
    '30%': {
      transform: 'translateY(0%)',
      opacity: 1
    }
  }
}));
