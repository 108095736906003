import React, {useState, useEffect} from 'react';
import {Fab, Badge} from '@material-ui/core';
import clsx from 'clsx'
import ChatArea from '../ChatArea'
import useStyles from './styles'
import {useConfigContext} from '../../_context/config.context'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


const launchButtonSize = 63

export default function Launcher() {
  const {config} = useConfigContext()
  const { WIDE_MODE, CHAT_OPEN_BY_DEFAULT, GA, USE_CUSTOM_LAUNCHER_ICON, USE_CUSTOM_LAUNCHER, LAUNCHER_BUTTON_ID, MINIMIZE_LAUNCHER} = config
  const [chatIsOpen, setChatIsOpen] = useState(CHAT_OPEN_BY_DEFAULT || WIDE_MODE)
  const [currentIcon, setCurrentIcon] = useState('far fa-comments')
  const [notificationCounter, setNotificationCounter] = useState(0)
  const classes = useStyles({ chatIsOpen, launchButtonSize, ...config })
  const [iconIsMinimised, setIconIsMinimised] = useState(false);

  const toggleOpenState = () => {
    if(!chatIsOpen) setNotificationCounter(0)
    setChatIsOpen(!chatIsOpen)
    !chatIsOpen ? setCurrentIcon('fas fa-times') : setCurrentIcon('far fa-comments')
     /* Icon is toggled based on the current state, do not pass down setChatIsOpen directly as it causes the icons to be ignored,
     pass down toggleOpenState to child components if you need to toggle the open state of the chat */

     // Create a message object with an identifier and send the message to the parent page
    const message = {
      source: 'pandai-widget',
      chatIsOpen: !chatIsOpen,
    };
    window.parent.postMessage(message, '*');
  }
  // const item = 'launch_icon.png'
  // if(USE_CUSTOM_LAUNCHER_ICON) {
  //   import(`../../assets/images/${item}`)
  //   .then(res => {
  //     console.log('image: ', res);
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   })
  // }

  const toggleIconState = () => {
    setIconIsMinimised(!iconIsMinimised)
  }

  const updateNotificationCount = () => {
    if(!chatIsOpen) setNotificationCounter(notificationCounter + 1)
  }
  
  // if (window.matchMedia('(display-mode: standalone)').matches) {
  //   alert('display-mode is standalone');
  // }
  
  useEffect(() => {
    // load google analytics once session begins
    const gtag = GA.length > 0 && window.gtag ? window.gtag : null
    if(gtag) {
      GA.forEach(({type, name, payload}) => {
        gtag(type, name, payload)
      })
    }
  }, [GA])

  return (
    <>
    <ChatArea
      triggerNotification={updateNotificationCount}
      launchButtonSize={launchButtonSize}
      chatIsOpen={chatIsOpen}
      toggleOpenState={toggleOpenState}
    />

  {MINIMIZE_LAUNCHER && !chatIsOpen && 
    <Fab
      data-testid='toggle-icon-minimize'
      aria-label="minimize-icon"
      disableRipple
      className={clsx(classes.iconMinimisationButton, iconIsMinimised && 'icon-minimised')}
      onClick={toggleIconState}
    >
      
    {!iconIsMinimised &&  
    <CloseRoundedIcon 
    className={clsx(classes.closeButton, iconIsMinimised && 'icon-minimised')}
    onClick={toggleIconState}
    />}
    </Fab>
    }

   {!iconIsMinimised && 
   <Fab
      data-testid='toggle-chat-window'
      aria-label="open-chat"
      disableRipple
      id={LAUNCHER_BUTTON_ID}
      className={clsx(classes.launcherButton, chatIsOpen && 'chat-open')}
      onClick={toggleOpenState}
    >
      <Badge
        badgeContent={notificationCounter > 0 ? notificationCounter : null}
        overlap="circle"
        classes={{
          badge: classes.badge
        }}
      >
        {
          !USE_CUSTOM_LAUNCHER_ICON && !USE_CUSTOM_LAUNCHER &&
          <i
            data-testid='launch-icon'
            className={
            clsx(classes.messageIcon, currentIcon, chatIsOpen && 'open')
            }
          />
        }
      </Badge>
    </Fab>
    }
    </>
  );
}
